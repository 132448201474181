var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButton',{attrs:{"text":"Nova Tag","type":"primario"},on:{"click":_vm.novaTagAdicional}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",staticClass:"formaPagamentoBody",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortBy":"descricao","sortDesc":false,"search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"devices",fn:function(ref){
var row = ref.row;
var col = ref.col;
return [_c('div',[_vm._v(" "+_vm._s(col ? col.join(',') : 'Nenhum')+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
var col = ref.col;
return [_c('div',[_c('span',{class:{ label: true, 'label-sim': col, 'label-nao': !col }},[_vm._v(" "+_vm._s(col ? 'Sim' : 'Não')+" ")])])]}},{key:"idtagcustomizada",fn:function(ref){
var row = ref.row;
var col = ref.col;
return [_c('div',[_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.opcoesTagsAdicionais(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }