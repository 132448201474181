<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>

  <div v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Nova Tag"
            type="primario"
            @click="novaTagAdicional"
          />
        </FormButtonGroup>
      </template>

      <DataTable
        class="formaPagamentoBody"
        :headers="headers"
        :endpoint="endpoint"
        sortBy="descricao"
        :sortDesc="false"
        ref="datatable"
        search
        @click="onClick"
      >
        <template v-slot:devices="{ row, col }">
          <div>
            {{ col ? col.join(',') : 'Nenhum' }}
          </div>
        </template>

        <template v-slot:status="{ row, col }">
          <div>
            <span :class="{ label: true, 'label-sim': col, 'label-nao': !col }">
              {{ col ? 'Sim' : 'Não' }}
            </span>
          </div>
        </template>

        <template v-slot:idtagcustomizada="{ row, col }">
          <div>
            <FormButtonMenu
              icon
              :options="tableOptions"
              @click="op => opcoesTagsAdicionais(row, op)"
            >
              <v-icon size="15">fas fa-ellipsis-v</v-icon>
            </FormButtonMenu>
          </div>
        </template>
      </DataTable>
    </Panel>

    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import MarketingModules from '@/components/MarketingModules'
import DataTable from '@/components/DataTable'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { TAGADICIONAL_REMOVE } from '@/store/actions/tagsAdicionais'

export default {
  name: 'FormaDePagamentoTable',
  components: {
    MarketingModules,
    Panel,
    FormButton,
    FormButtonGroup,
    DataTable,
    FormButtonMenu
  },
  data: () => ({
    headers: [
      { text: 'Descricao', value: 'descricao' },
      { text: 'Dispositivos', value: 'devices', align: 'center' },
      { text: 'Em uso', value: 'status', align: 'center' },
      { text: 'Local', value: 'local', align: 'center' },
      {
        text: 'Opções',
        value: 'idtagcustomizada',
        align: 'center',
        clickable: false
      }
    ],
    modulosForm: [ModulesEnum.TODOS],
    tableOptions: [
      { text: 'Editar', value: 'editar' },
      { text: 'Deletar', value: 'deletar' }
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    endpoint() {
      return `/api/v4/tag-customizada/${this.getFornecedorId}/lista-datatable`
    }
  },
  methods: {
    novaTagAdicional() {
      this.$router.push('tags-adicionais/novo')
    },
    opcoesTagsAdicionais(row, op) {
      const acao = {
        editar: () => {
          const { idtagcustomizada } = row
          this.$router.push(
            `tags-adicionais/${this.getFornecedorId}/${idtagcustomizada}`
          )
        },
        deletar: () => {
          const { descricao, idtagcustomizada } = row

          this.$swal
            .fire({
              title: 'Exclusão de tag',
              text: `Deseja realmente remover a tag "${descricao}"? Esta operação é irreversível.`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            })
            .then(async result => {
              try {
                if (result.value) {
                  await this.$store.dispatch(TAGADICIONAL_REMOVE, {
                    idfornecedor: this.getFornecedorId,
                    idtagcustomizada: idtagcustomizada
                  })

                  this.$vueOnToast.pop('success', 'Tag apagada com sucesso')

                  this.$refs.datatable.reload()
                }
              } catch (error) {
                if ((error && error.message) || error.err) {
                  this.$vueOnToast.pop('error', error.message || error.err)
                }

                this.$vueOnToast.pop('error', error)
              }
            })
        }
      }

      acao[op.value]()
    },
    onClick(item) {
      const { idtagcustomizada } = item
      this.$router.push(
        `tags-adicionais/${this.getFornecedorId}/${idtagcustomizada}`
      )
    },
    onClose() {
      this.$refs.datatable.reload()
    }
  },
  watch: {
    $route(to) {
      if (to.path === '/fornecedor/configuracao/tags-adicionais') {
        this.$refs.datatable.reload()
      }
    }
  }
}
</script>

<style scoped>
.label {
  display: inline;
  padding: 5px 10px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-sim {
  background-color: #62cb31;
}

.label-nao {
  background-color: #d94949;
}
</style>
